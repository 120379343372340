<template>
  <div class="white pa-12">
    <div class="text-center mb-4">
      <v-progress-circular
        indeterminate
        :size="200"
        :width="25"
        color="primary"
      />
    </div>
    <h1 class="text-center">
      {{ $t('payment.successful-payment-info-create-billing') }}
    </h1>
  </div>
</template>
<script>
export default {}
</script>
