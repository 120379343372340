<template>
  <div class="w-full pa-3">
    <ContentCreator />
  </div>
</template>

<script>

export default {
  components: {
    ContentCreator: () => import('@/modules/contentCreator')
  }
}
</script>
<style scoped>
.content-creator-container {
  width: 100%;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;
}
</style>
